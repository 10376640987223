<template>
     <ul class="userlinks-container">
      <a class="userlinks" 
         v-bind:href="link.url" 
         target="_blank" 
         v-for="link in links" :key="link.userName">

        <a>
          {{ link.name }}
        </a>
      </a>
  </ul>
</template>

<script>
// Importing array from local JSON file
import json from "../../public/user.json";

export default {
  name: "userLinks",
  data: function() {
    return {
      links: json.links, // passing array data into Vue
    };
  },

// Checking if everything works, delete after everything works
  mounted: function() {
    console.log(this.links);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color:  lightgrey;
  text-decoration: none;
}

a:hover {
  color: lightgrey;
  cursor: pointer;
}

.userlinks-container {
  position: relative;
  display: flex;
  flex-direction: column;
  list-style: none;
}

ul {
align-items: center;
}

.userlinks {
  border: 1px grey solid;
  margin: 10px;
  height: max-content;
  min-width: 240px;
  max-width: 260px;
  padding: 10px 20px 10px 20px;
}

</style>
