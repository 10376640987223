<template>

<div class="userName-container">
    <h1>{{ userName.userName }}</h1>
</div>
</template>

<script>
// Importing array from local JSON file
import userName from "../../public/user.json";

// export default 
export default {
  name: "userName",
  data: function() {
    return {
      userName: userName, // passing array data into Vue
    };
  },

// Checking if everything works, delete after everything works
  mounted: function() {
    console.log(this.userName);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {
  font-size: 1em;
  text-decoration: none;
  color: white;
  margin: 20px;
  height: max-content;
  width: max-content;
  padding: 10px 20px 10px 20px;
}

.userName-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
