<template>
  <div id="app">

    <section>

      <article>
        <userImage/>
        <userName/>
        <userLinks/>
      </article>

    </section>

  </div>
</template>

<script>
import './assets/css/destyle.css';
import userLinks from './components/links.vue'
import userName from './components/username.vue'
import userImage from './components/userimage.vue'

export default {
  name: 'App',
  metaInfo: {
    title: 'Charlie Edward Brooks',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Charlie Edward Brooks'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]

  },
  components: {
    userLinks,
    userName,
    userImage,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}

html {
  background-color: #3b3b3b;
}

section {
  width: 100vw;
  display: grid;
  grid-template-columns: auto 300px auto;
}

article {
  grid-column: 2 / span 1;
}

@media screen and (max-width: 1000px) {
  
  section { 
    grid-template-columns: auto;
  }

  article {
  grid-column: 1 / span 1;
}

}



</style>
