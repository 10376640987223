<template>

<div class="userImage-container">
    <img :src="userImage.userImage" />
</div>
</template>

<script>
// Importing array from local JSON file
import userImage from "../../public/user.json";


// export default 
export default {
  name: "userImage",
  data: function() {
    return {
      userImage: userImage, // passing array data into Vue
    };
    
  },

// Checking if everything works, delete after everything works
  mounted: function() {
    console.log(this.userImage);
  },

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {
  font-size: 1em;
  text-decoration: none;
  color: white;
  margin: 20px;
  height: max-content;
  width: max-content;
  padding: 10px 20px 10px 20px;
}

.userimage-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

img {
  width: 200px
}
</style>
